import React from "react";

const Presentation = () => {
  return (
    <section id="about5" className="about about-4 about-5">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-10">
            <div className="heading mb-50">
              <h2>
                Azerty est un studio de création de sites internet, basé à
                Nantes
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="about-panel">
              <div className="about-icon">
                <h6>Histoire</h6>
              </div>
              <div className="about-content">
                <p>
                  Azerty est né de l'association entre trois expertises : le
                  développement web, le référencement et l'analyse de données.
                  Convaincus que ces expertises sont indissociables dans le
                  succès d'un projet digital, nous avons conçu une offre qui
                  renforce le lien entre ces domaines.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="about-panel">
              <div className="about-icon">
                <h6>Mission</h6>
              </div>
              <div className="about-content">
                <p>
                  Nous vous accompagnons dans le développement de votre
                  stratégie de marketing digital au moyen d'outils
                  technologiques modernes. Nous utilisons ces outils pour nos
                  propres sites et souhaitons en faire bénéficier nos clients.
                </p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="about-panel">
              <div className="about-icon">
                <h6>Valeurs</h6>
              </div>
              <div className="about-content">
                <p>
                  Nous sommes passionnés par ce que nous faisons et aimons
                  découvrir de nouvelles technologies pour proposer des offres
                  de qualité. Nous privilégions avant tout la dimension humaine,
                  l’échange et la pédagogie pour accompagner nos clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Presentation;
