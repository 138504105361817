import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Banner from "../components/banner";

import Presentation from "../components/a-propos/bloc-presentation";
import Equipe from "../components/a-propos/bloc-equipe";
import Techno from "../components/a-propos/bloc-techno";

const APropos = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Qui sommes-nous ?"
        description="Azerty est né de l'association entre trois expertises : le développement web, le référencement et l'analyse de données. "
      />
      <Banner
        title="A propos"
        baseline="Qui sommes-nous ?"
        imageBanner={data.imageBanner.childImageSharp.fluid}
      />
      <Presentation />
      <Equipe
        imageVincent={data.imageVincent.childImageSharp.fluid}
        imageCharlotte={data.imageCharlotte.childImageSharp.fluid}
        imageCaroline={data.imageCaroline.childImageSharp.fluid}
      />
      <Techno />
    </Layout>
  );
};

export default APropos;

export const pageQuery = graphql`
  query {
    imageVincent: file(relativePath: { eq: "team/vincent.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCharlotte: file(relativePath: { eq: "team/charlotte.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageCaroline: file(relativePath: { eq: "team/Caro.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageBanner: file(relativePath: { eq: "background/bannerAPropos.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
