import React from "react";
import Img from "gatsby-image";

const Equipe = ({ imageVincent, imageCharlotte, imageCaroline }) => {
  return (
    <section id="team1" className="team team-1 bg-gray">
      <div className="container">
        <div className="heading heading-2">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="subheading">Nous aimons ce que nous faisons</div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <h2>L'équipe</h2>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <p>
                Nous avons 10 ans d'expérience dans le développement de sites et
                le webmarketing et avons accompagné plusieurs centaines
                d'entreprises dans leur stratégie digitale.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="team-panel">
              <div className="team-img">
                <Img fluid={imageCharlotte} alt="charlotte cady" />
                <div className="team-overlay">
                  <a
                    href="https://www.linkedin.com/in/charlottecady/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Linkedin
                  </a>
                </div>
              </div>
              <div className="team-content">
                <h3>Charlotte Cady</h3>
                <p>Développeuse Front-end</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="team-panel">
              <div className="team-img">
                <Img fluid={imageVincent} alt="vincent pelage" />
                <div className="team-overlay">
                  <a
                    href="https://www.linkedin.com/in/vincent-pelage/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Linkedin
                  </a>
                </div>
              </div>
              <div className="team-content">
                <h3>Vincent Pélage</h3>
                <p>Développeur Fullstack</p>
              </div>
            </div>
          </div>

          <div className="col-sm-12 col-md-4 col-lg-4">
            <div className="team-panel">
              <div className="team-img">
                <Img fluid={imageCaroline} alt="Caroline Poinsot" />
                <div className="team-overlay">
                  <a
                    href="https://www.linkedin.com/in/caroline-poinsot-8a6154100/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Linkedin
                  </a>
                </div>
              </div>
              <div className="team-content">
                <h3>Caroline Poinsot</h3>
                <p>Directrice artistique</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Equipe;
