import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

import ReactLogo from "../../assets/images/logo/react.png";
import Redux from "../../assets/images/logo/redux.png";
import Vue from "../../assets/images/logo/vueJS.png";
import Nuxt from "../../assets/images/logo/nuxt.png";
import Gatsby from "../../assets/images/logo/gatsby.png";
import GrahQl from "../../assets/images/logo/graphQL.png";
import MongoDb from "../../assets/images/logo/mongoDB.png";
import Webpack from "../../assets/images/logo/webpack2.png";
import Express from "../../assets/images/logo/express.png";

const Section = styled.section`
  @media (max-width: 991px) {
    .slick-dots {
      bottom: -50px;
    }
  }
`;

const Image = styled.img`
  max-width: 100px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  @media (max-width: 767px) {
    max-width: 90px;
  }
`;

const Techno = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
          // dots: true
        }
      }
    ]
  };
  return (
    <Section id="clients2" className="clients clients-1 clients-2 pb-100">
      <div className="container">
        <div className="row heading heading-2">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="subheading">Langages et outils</div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4">
            <h2 className="center">Nos technos </h2>
          </div>
          {/* <div className="col-sm-12 col-md-12 col-lg-6">
            <p>
              We've been lucky to collaborate with a long list of customers,
              located in and out of the country. Thanks to them we have grown as
              professionals.
            </p>
          </div> */}
        </div>
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="pt-30 pb-30">
              <Slider {...settings}>
                <div className="client">
                  <Image src={ReactLogo} alt="logo" />
                </div>
                <div className="client">
                  <Image src={Gatsby} alt="logo" />
                </div>
                <div className="client">
                  <Image src={GrahQl} alt="logo" />
                </div>
                <div className="client">
                  <Image src={MongoDb} alt="logo" />
                </div>
                <div className="client">
                  <Image src={Webpack} alt="logo" />
                </div>
                <div className="client">
                  <Image src={Redux} alt="logo" />
                </div>
                <div className="client">
                  <Image src={Express} alt="logo" />
                </div>
                <div className="client">
                  <Image src={Vue} alt="logo" />
                </div>
                <div className="client">
                  <Image src={Nuxt} alt="logo" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Techno;
